import { getSystemSettingsById } from '@services/vueSystemSettingsSvc';
import { getObjProperty } from "@utilities/mrVueUtils";

const state = {
  rcdSystemSettings: {},
  rcdSystemSettingsLoaded: false,
  globalFeaturedProduct: {},
  purchasePanelBulkAndMembership: false,
  thirdPartyPaymentsSystemSettingsLoading: false,
  thirdPartyPaymentsSystemSettingsLoaded: false,
  thirdPartyPaymentsSystemSettings: {},
  captchaSystemSettings: {},
};

const getters = {};
const actions = {
  initRcdSystemSettings({ state, dispatch }) {
    if (state.rcdSystemSettingsLoaded) {
      return;
    }

    dispatch('getRcdSystemSettings');
  },

  initThirdPartyPaymentsSystemSettings({ state, dispatch }) {
    if (state.thirdPartyPaymentsSystemSettingsLoading || state.thirdPartyPaymentsSystemSettingsLoaded) {
      return;
    }

    dispatch('getThirdPartyPaymentsSystemSettings');
  },

  getRcdSystemSettings({ commit }) {
    getSystemSettingsById({ id: 'returnCustomerDashboard' }).then(res => {
      commit('setRcdSystemSettings', res.data.settings);
      commit('setRcdSystemSettingsLoaded', true);
    });
  },

  getGlobalFeaturedProduct({ commit }) {
    getSystemSettingsById({ id: 'globalFeaturedProduct' }).then(res => {
      commit('setGlobalFeaturedProduct', res.data.settings);
    });
  },

  getPurchasePanelBulkAndMembershipSettings({ commit }) {
    getSystemSettingsById({ id: 'purchasePanelBulkAndMembership' }).then(res => {
      commit('setPurchasePanelBulkAndMembership', (getObjProperty(res, 'data.settings.enabled') || false));
    });
  },

  getThirdPartyPaymentsSystemSettings({ commit }) {
    commit('setThirdPartyPaymentsSystemSettingsLoading', true);
    getSystemSettingsById({ id: 'thirdPartyPayments' }).then(res => {
      commit('setThirdPartyPaymentsSystemSettings', (getObjProperty(res, 'data.settings')));
      commit('setThirdPartyPaymentsSystemSettingsLoading', false);
      commit('setThirdPartyPaymentsSystemSettingsLoaded', true);
    }).catch(() => {
      commit('setThirdPartyPaymentsSystemSettingsLoading', false);
    });
  },

  getCaptchaSystemSettings({ commit }) {
    getSystemSettingsById({ id: 'captcha' }).then(res => {
      commit('setCaptchaSystemSettings', (getObjProperty(res, 'data.settings')));
    });
  }
};

const mutations = {
  setRcdSystemSettings(state, val = {}) {
    state.rcdSystemSettings = val;
  },

  setRcdSystemSettingsLoaded(state, val = false) {
    state.rcdSystemSettingsLoaded = Boolean(val);
  },

  setGlobalFeaturedProduct(state, val = {}) {
    state.globalFeaturedProduct = val;
  },

  setPurchasePanelBulkAndMembership(state, val = {}) {
    state.purchasePanelBulkAndMembership = val;
  },

  setThirdPartyPaymentsSystemSettings(state, val = {}) {
    state.thirdPartyPaymentsSystemSettings = val;
  },

  setThirdPartyPaymentsSystemSettingsLoading(state, val = false) {
    state.thirdPartyPaymentsSystemSettingsLoading = Boolean(val);
  },

  setThirdPartyPaymentsSystemSettingsLoaded(state, val) {
    state.thirdPartyPaymentsSystemSettingsLoaded = Boolean(val);
  },

  setCaptchaSystemSettings(state, val = {}) {
    state.captchaSystemSettings = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};