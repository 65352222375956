
import vueSubscriptionSvc from '@services/vueSubscriptionSvc';
import dataToolSvc from "@services/dataToolSvc";
import { shipRefillWithCart } from "@services/vueCartSvc";
import { getObjProperty } from "@utilities/mrVueUtils";
import { subscriptionStatus } from '@constants';

import shuffle from 'lodash.shuffle';

const state = {
  savingSub: false,
  subs: [],
  subInEdit: {},
  cancelReasons: [],
  cancelFlowPauseModalCancelReasons: [],
  cancelData: {
    reason: {},
    comment: null
  },
  subsToUpdate: [],
  defaultFrequencies: '2, 3, 4, 5, 6, 7, 8',
  loadingSubs: false,
  subInEditUpdated: 0,
  postCancelMethod: null, //- can execute this after sub has been canceled
  processingPickUp: false
};

export const getters = {
  URMCancelReasons(state) {
    const { cancelReasons = [] } = state;
    return shuffle([
      ...cancelReasons,
      {
        reason: 'I was not able to use the membership as often as I thought',
        product_type: 'renewable_cb_membership',
        action: 'goToSubreasons'
      }
    ].filter(({ product_type, id }) =>
      product_type === 'renewable_cb_membership' &&
      ![21, 25, 27].includes(id)
    ));
  },

  URMCancelSubreasons(state) {
    const { cancelReasons = [] } = state;
    return cancelReasons.filter(({ product_type, id }) =>
      product_type === 'renewable_cb_membership' &&
      [21, 25, 27].includes(id));
  },

  limitlessPlusCancelReasons(state) {
    const { cancelReasons = [] } = state;
    return cancelReasons.filter(({ product_type }) => product_type === 'omni_memb');
  },

  subscriptionIds(state) {
    return state.subs.map(sub => sub.id);
  },

  activeSubscriptionIds(state) {
    return state.subs.filter(sub => !sub.inactive).map(sub => sub.id);
  },

  activeSubscriptions(state) {
    return state.subs.filter(sub => !sub.inactive);
  },

  activeSubscriptionsByProductType(state, getters) {
    let activeSubsByProductTypeMap = {};

    if (getters.activeSubscriptions && getters.activeSubscriptions.length) {
      getters.activeSubscriptions.forEach(activeSub => {
        if (!activeSubsByProductTypeMap[activeSub.product_info.product_type]) {
          activeSubsByProductTypeMap[activeSub.product_info.product_type] = [];
        }

        activeSubsByProductTypeMap[activeSub.product_info.product_type].push(activeSub);
      });
    }

    Object.keys(activeSubsByProductTypeMap).forEach(key => {
      activeSubsByProductTypeMap[key].sort((a, b) => {
        return (+new Date(a.next)) - (+new Date(b.next));
      });
    });

    return activeSubsByProductTypeMap;
  },

  inactiveSubs(state) {
    return state.subs.filter(sub => sub.inactive);
  },

  isInactiveSubProductVisibleAndActive(state, getters) {
    let inactiveSub = getters.inactiveSubs[0];
    return (getObjProperty(inactiveSub, 'products') || []).find(p => p.product_id == inactiveSub.product_id && p.product.status && p.product.visible);
  },

  activeSubTypeSet(state) {
    return new Set(state.subs.map(sub => sub.product_info.product_type));
  },

  subsGroupedByNext(state) {
    var subsGroupedByNext = [];

    var subObj = state.subs.reduce((rv, sub) => {
      if (!sub.inactive) {
        (rv[sub.next] = rv[sub.next] || []).push(sub);
      }
      return rv;
    }, {});

    Object.keys(subObj).forEach(key => {
      subsGroupedByNext.push(subObj[key]);
    });

    return subsGroupedByNext;
  },

  subsGroupedByNextWithOnTheWay(state, getters, rootState, rootGetters) {
    var subsGroupedByNextWithOnTheWay = [];

    var subObj = state.subs.reduce((rv, sub) => {
      if (!sub.inactive) {
        var onTheWayKey = rootGetters['customer/onTheWaySubsMap'][sub.id] ? 'onTheWay' : '';
        (rv[onTheWayKey + sub.next] = rv[onTheWayKey + sub.next] || []).push(sub);
      }
      return rv;
    }, {});
    //- putting onTheWay at the begining of the array
    Object.keys(subObj).forEach(key => {
      if (key.includes('onTheWay')) {
        subsGroupedByNextWithOnTheWay.push(subObj[key]);
      }
    });
    //- add the rest to the end
    Object.keys(subObj).forEach(key => {
      if (!key.includes('onTheWay')) {
        subsGroupedByNextWithOnTheWay.push(subObj[key]);
      }
    });

    return subsGroupedByNextWithOnTheWay;
  },

  promotedReactivatableSubs(state, getters) {
    let promotedSubs = [];
    let inactiveSubs = getters.inactiveSubs;

    if (getters.activeSubTypeSet.has('color_kit')) {

      for (let x = 0; x < inactiveSubs.length; x++) {
        if (!getters.activeSubTypeSet.has(inactiveSubs[x].product_info.product_type)) {
          promotedSubs.push(inactiveSubs[x]);
        }
        if (promotedSubs.length >= 2) {
          x = inactiveSubs.length;
        }
      }
    } else if (getters.getSubsByProdTypeAndStatus('color_kit', 'canceled').length) {
      for (let x = 0; x < inactiveSubs.length; x++) {
        if (inactiveSubs[x].product_info.product_type == 'color_kit') {
          promotedSubs.push(inactiveSubs[x]);
        }
        if (promotedSubs.length >= 2) {
          x = inactiveSubs.length;
        }
      }
    }

    return promotedSubs;
  },

  hasMultipleActionableSubs(state, getters) {
    return (state.subs.length + getters.promotedReactivatableSubs.length) > 1;
  },

  nextSubShipment(state, getters) {
    let next = [];

    if (getters.subsGroupedByNext && getters.subsGroupedByNext.length) {
      next = getters.subsGroupedByNext[0];
    } else if (getters.inactiveSubs.length) {
      //- if user does not have any active subs with next date pull one from inactive
      //- and we will suggest them to reactivate it
      next = [getters.inactiveSubs[0]];
    }

    return next;
  },

  /**
   * retrieves the next shipment date for sub by id
   * @param {object} state
   * @param {object} getters
   * @returns {Function} returns a function that accepts id{number} as param and returns an ISO datestring{string}
   */
  getNextSubShipmentDateById: (state, getters) => (id) => {
    return getters.getSubById(id).next;
  },

  getSubById: (state) => (id) => {
    return state.subs.find(sub => sub.id == id) || {};
  },

  getSubsByProdTypeAndStatus: (state) => (product_type, status) => {
    return state.subs.filter(sub => sub.status == status && sub.product_info.product_type == product_type);
  },

  productInSub: (state, getters) => (subId, productId) => {
    let sub = getters.getSubById(subId);
    return (sub.products || []).some(prod => prod.product_id == productId);
  },

  productTypeInSub: (state, getters) => (subId, productType) => {
    let sub = getters.getSubById(subId);
    return (sub.products || []).some(prod => getObjProperty(prod, 'product.product_type') == productType);
  },

  subHasPerfectPair: (state, getters, rootState) => (subId) => {
    return getters.productInSub(subId, rootState.constants.perfectPairInstructionId) && getters.productTypeInSub(subId, 'gloss');
  }
};

export const actions = {
  //- use this when need to ensure that subs are loaded
  initSubscriptions({ state, dispatch }) {
    if (state.subs.length) {
      return;
    }

    // reduce 404 errors
    if (!window.cdata || !window.cdata.id) {
      return;
    }

    dispatch('loadCustomerSubs');
  },

  //- subs refresh/load
  loadCustomerSubs({ commit, dispatch, rootState }) {
    //- when customer is still loading restart
    if (rootState.customer.loadingCustomer) {
      setTimeout(() => {
        dispatch('loadCustomerSubs');
      }, 100);
      return;
    }

    commit('setLoadingSubs', true);
    return vueSubscriptionSvc.getSubscriptions().then(res => {
      let sortedSubs = (res.data.subs || []).sort((a, b) => {
        return (+new Date(a.next)) - (+new Date(b.next));
      });

      commit('setSubs', sortedSubs);
      commit('setLoadingSubs', false);
    }).catch(() => commit('setLoadingSubs', false));
  },

  populateSubInEditById({ commit, getters }, params) {
    if (params && params.subId) {
      commit('setSubInEdit', getters.getSubById(params.subId));
    }
  },

  openPostponeModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: 'PostponeSubscriptionModal',
    }, { root: true });
  },

  editAutoDeliveryAddressModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: 'EditAutoDeliveryAddressModal',
    }, { root: true });
  },

  editAutoDeliveryPaymentModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryPaymentModal`,
    }, { root: true });
  },

  editMembershipPaymentModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryPaymentModal`,
    }, { root: true });
  },

  editAutoDeliveryFrequencyModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryFrequencyModal`,
    }, { root: true });
  },

  editAutoDeliveryChangeColorModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryChangeColorModal`,
      theme: params.theme,
      props: {
        showTakeAdvisorModal: params.showTakeAdvisorModal
      }
    }, { root: true });
  },

  editAutoDeliveryCancelModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryCancelModal`
    }, { root: true });
  },

  editAutoDeliveryCancelReasonsModal({ dispatch }, params) {
    dispatch('populateSubInEditById', params);
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryCancelReasonsModal`,
    }, { root: true });
  },

  editAutoDeliveryCancelOfferModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryCancelOfferModal`,
    }, { root: true });
  },

  editAutoDeliveryCancelFinalModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryCancelFinalModal`
    }, { root: true });
  },

  editAutoDeliveryCancelBulkOrderModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryCancelBulkOrderModal`,
      theme: 'bg-ui-color-3',
    }, { root: true });
  },

  editAutoDeliveryMembershipModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryMembershipModal`,
      theme: 'bg-ui-color-3',
    }, { root: true });
  },

  editAutoDeliveryPauseModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryPauseModal`,
    }, { root: true });
  },

  editAutoDeliveryChooseDateModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: `EditAutoDeliveryChooseDateModal`,
    }, { root: true });
  },

  openPostCancelReturnToSalonModal({ dispatch }) {
    dispatch('modal/showModal', {
      component: 'EditAutoDeliveryPostCancelReturnToSalonModal',
      theme: 'app-modal-small bottom-mobile-slide'
    }, { root: true });
  },

  updateSubscription({ commit, dispatch, state, rootState }, params) {
    if (state.savingSub) {
      return;
    }

    commit('setSavingSub', true);
    return new Promise((resolve, reject) => {
      vueSubscriptionSvc.updateSubscription(params).then(({ data: { success, subscriptionHistoryId } = {} }) => {
        dispatch('payments/getCustomerPaymentProfiles', {}, { root: true });
        dispatch('loadCustomerSubs');
        dispatch('customer/refreshCustomerCdata', null, { root: true });
        if (rootState.refillCart.refillCartLoaded) {
          dispatch('refillCart/loadRefillCart', null, { root: true });
        }
        let successNotification = 'You have successfully updated your subscription.';
        if (getObjProperty(params, 'changes.status') === subscriptionStatus.ACTIVE) {
          successNotification = 'Welcome back, you have successfully reactivated your subscription.';
        }
        dispatch('notifySuccess', successNotification, { root: true });

        commit('setSavingSub', false);
        commit('setSubInEditUpdated');
        resolve({ success, subscriptionHistoryId });
      }).catch(() => {
        commit('setSavingSub', false);
        dispatch('notifyError', null, { root: true });
        reject();
      });
    });
  },

  updateSubscriptionColor({ commit, dispatch, state }, params) {
    if (state.savingSub) {
      return;
    }

    commit('setSavingSub', true);
    return new Promise((resolve, reject) => {
      vueSubscriptionSvc.updateSubscriptionColor(params).then(() => {
        dispatch('loadCustomerSubs');
        resolve();
        dispatch('notifySuccess', 'You have successfully changed your subscription color.', { root: true });
        commit('setSavingSub', false);
      }).catch(() => {
        commit('setSavingSub', false);
        dispatch('notifyError', null, { root: true });
        reject();
      });
    });
  },

  addSubAttachment({ dispatch, getters }, params) {
    //- this function accepts product_id or product_ids as an array to add multiple products
    let { sub_id, product_id, product_ids = [], qty, ctaSource } = params;
    let sub = getters.getSubById(sub_id);

    let payload = {
      sub_id,
      changes: {
        products: [...sub.products]
      },
      ctaSource
    };

    if (product_id) {
      let attachable = {
        product_id,
        qty
      };

      payload.changes.products.push(attachable);
    }

    product_ids.forEach(id => payload.changes.products.push({ product_id: id, qty }));

    return new Promise((resolve, reject) => {
      dispatch('updateSubscription', payload).then(resolve).catch(reject);
    });
  },

  updateSubAttachmentQty({ dispatch, getters }, params) {
    let { sub_id, attachment_id, qty, ctaSource, payment_id } = params;
    let sub = getters.getSubById(sub_id);
    let products = JSON.parse(JSON.stringify(sub.products));

    products.forEach(prod => {
      if (prod.product_id == attachment_id) {
        prod.qty = qty;
      }
    });

    let payload = {
      sub_id,
      changes: {
        products,
        payment_id
      },
      ctaSource
    };

    return new Promise((resolve, reject) => {
      dispatch('updateSubscription', payload).then(resolve).catch(reject);
    });
  },

  removeSubAttachment({ dispatch, getters }, params) {
    let { sub_id, attachment_id, attachment_ids = [], ctaSource, payment_id } = params;
    let sub = getters.getSubById(sub_id);
    let removeProductIds = [];

    removeProductIds.push(attachment_id);
    removeProductIds = removeProductIds.concat(attachment_ids);

    let products = sub.products.filter(prod => !removeProductIds.includes(prod.product_id));

    let payload = {
      sub_id,
      changes: {
        products,
        payment_id
      },
      ctaSource
    };

    return new Promise((resolve, reject) => {
      dispatch('updateSubscription', payload).then(resolve).catch(reject);
    });
  },

  cancelSubscription({ commit, dispatch, state }, params) {
    if (state.savingSub) {
      return;
    }

    const isMembership = !!params.isMembership;
    let successMessage = 'Your subscription has been canceled';
    if (isMembership) {
      successMessage = 'Your membership has been canceled';
    }

    commit('setSavingSub', true);
    return new Promise((resolve, reject) => {
      vueSubscriptionSvc.cancelSubscription(params)
        .then(() => {
          dispatch('loadCustomerSubs');
          dispatch('customer/refreshCustomerCdata', null, { root: true });
          resolve();
          commit('setSavingSub', false);
          dispatch('notifySuccess', successMessage, { root: true });
        })
        .catch(() => {
          reject();
          commit('setSavingSub', false);
          dispatch('notifyError', null, { root: true });
        });
    });
  },

  shipNow({ dispatch }, params) {
    shipRefillWithCart({
      subscriptionId: params.subId,
      includeSubsWithSameNext: params.includeSubsWithSameNext || false,
      nonPersistent: true
    }).then(() => {
      location.href = '/checkout';
    }).catch(error => {
      var errMsg = 'We were not able to ship this delivery now. Please try again or call Color Crew';

      if (error.response && error.response.data && error.response.data.code == 'PENDING_ORDERS') {
        errMsg = error.response.data.message;
      }

      dispatch('notifyError', errMsg, { root: true });
    });
  },

  pickItUp({ commit, dispatch }, params) {
    dispatch('colorbar/pickItUpSubs', params.subId, { root: true });
    dispatch('modal/showModal', {
      component: 'ChangeLocationModal',
      props: {
        subscriptionId: params.subId,
        onChangedLocation: (locationId) => {
          commit('setProcessingPickUp', true);
          shipRefillWithCart({
            subscriptionId: params.subId,
            includeSubsWithSameNext: params.includeSubsWithSameNext || false,
            nonPersistent: true
          }).then(() => {
            dispatch('cart/setLocationId', { locationId }, { root: true })
              .then(() => location.href = '/checkout');
          }).catch(error => {
            commit('setProcessingPickUp', false);
            var errMsg = 'We were not able to ship this delivery now. Please try again or call Color Crew';

            if (error.response && error.response.data && error.response.data.code == 'PENDING_ORDERS') {
              errMsg = error.response.data.message;
            }

            dispatch('notifyError', errMsg, { root: true });
          });
        }
      }
    }, { root: true });
  },

  getCancelReasons({ commit }, params) {
    let payload = {
      product_type: params.product_type,
      randomize: true
    };

    vueSubscriptionSvc.getCancelReasons(payload)
      .then(res => {
        var hasNNN = params.code.indexOf('NNN') < -1;

        var cancelReasons = res.data.reasons.map((reason) => {
          reason.mention_nnn = !hasNNN && reason.mention_nnn;
          return reason;
        });

        commit('setCancelReasons', cancelReasons);
      });
  },

  getCancelFlowOptions({ commit }) {
    dataToolSvc.getData({
      mixinKey: 'cancel_flow_options'
    }).then(res => {
      commit('setCancelFlowPauseModalCancelReasons', res?.data?.pauseModalCancelReasons);
    });
  },

  clearCancelData({ commit }) {
    commit('setCancelReason');
    commit('setCancelComment');
  },

  updateSubsToUpdate({ commit }, val) {
    commit('setSubsToUpdate', val);
  },

  getAvailabilityByRegionForSubscription({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      vueSubscriptionSvc.getAvailabilityByRegionForSubscription(params)
        .then(res => {
          if (!params.noPersist) {
            dispatch('cart/updateAvailableLocations', res.data, { root: true });
          }
          resolve(res.data);
        }).catch(reject);
    });
  }
};

export const mutations = {
  setSubs(state, val) {
    state.subs = val;
  },

  setSubInEdit(state, subscription) {
    state.subInEdit = Object.assign({}, subscription);
  },

  setSubInEditFrequency(state, val) {
    state.subInEdit.frequency = val;
  },

  setSubInEditNext(state, val) {
    state.subInEdit.next = val;
  },

  setCancelReasons(state, val) {
    state.cancelReasons = val || [];
  },

  setCancelFlowPauseModalCancelReasons(state, val) {
    state.cancelFlowPauseModalCancelReasons = val || [];
  },

  setCancelReason(state, val) {
    state.cancelData.reason = val || {};
  },

  setCancelComment(state, val) {
    state.cancelData.comment = val;
  },

  clearSubInEdit(state) {
    state.subInEdit = {};
  },

  setSavingSub(state, val) {
    state.savingSub = val;
  },

  setSubsToUpdate(state, val) {
    state.subsToUpdate = val || [];
  },

  setLoadingSubs(state, val) {
    state.loadingSubs = Boolean(val);
  },

  setPostCancelMethod(state, val) {
    state.postCancelMethod = val;
  },

  setSubInEditUpdated(state) {
    state.subInEditUpdated += 1;
  },

  setProcessingPickUp(state, val) {
    state.processingPickUp = Boolean(val);
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};